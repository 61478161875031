import React, { useEffect, useState } from 'react';
import serverApi from '../../app/serverAPI';
import { IAuthState } from './authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getCredentials } from './authSlice';
import { selectToken } from './authSlice';
import AppRouter from '../../routes/AppRouter';
import { Box, CircularProgress, Container } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const PersistLogin = (): JSX.Element => {
  const [isLoading, setIsLoding] = useState(true);
  const accessToken = useSelector(selectToken);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const refresh = async () => {
      try {
        const { data } = await serverApi.get<IAuthState>('/auth/refresh');
        dispatch(getCredentials({ accessToken: data.accessToken!, user: data.user }));
        if (location.pathname === '/') navigate('/projects');
      } catch (e) {
        console.log('not logged in');
      } finally {
        setIsLoding(false);
      }
    };

    if (!accessToken) {
      refresh();
    } else {
      setIsLoding(false);
    }
  }, []);

  const loading = (
    <Container
      maxWidth="sm"
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        height: '50px',
        width: '50px',
      }}
    >
      <CircularProgress />
    </Container>
  ) as JSX.Element;

  return isLoading ? loading : <AppRouter />;
};

export default PersistLogin;
