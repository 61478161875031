import { Route, Routes } from 'react-router-dom';
import Users from '../features/user/Users';
import Platform from '../features/platform/Platform';
import SignIn from '../features/auth/SigninForm';
import Profile from '../features/auth/Profile';
import EditProfile from '../features/auth/EditProfile';
import AddUser from '../features/user/AddUser';
import AddProject from '../features/project/AddProject';
import ProjectTable from '../features/project/ProjectTable';
import ProjectTabs from '../features/project/ProjectTabs';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/edit" element={<EditProfile />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/projects">
        <Route path="" element={<ProjectTable />} />
        <Route path=":projectId" element={<ProjectTabs />} />
        <Route path="add" element={<AddProject />} />
      </Route>
      <Route path="/users" element={<Users />} />
      <Route path="/users/add" element={<AddUser />} />
      <Route path="/platforms" element={<Platform />} />
    </Routes>
  );
};

export default AppRouter;
