import { useState } from 'react';
import { IPlatform, IProject, setPipeline, setPlatforms } from './projectSlice';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { showMessage } from '../alert/alertSlice';
import useApi from '../../hooks/useApi';

const DeployProject = ({ project }: { project: IProject }): JSX.Element => {
  const serverApi = useApi();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(
    project.platforms!.map(p => ({ id: p.id, checked: false, name: p.name })),
  );
  const radioOptions = ['development', 'production'];
  const [radioChecked, setRadioChecked] = useState(radioOptions[0]);

  const onDeployClick = async () => {
    const projectId = project.id;
    const platforms = checked
      .filter(c => c.checked)
      .map(c => c.name)
      .join(',');

    const stage = radioChecked;

    const sendDeployRequest = async () => {
      try {
        if (platforms) {
          await serverApi.post(`/pipelines/${projectId}`, { platforms, stage });

          dispatch(setPipeline({ projectId: project.id, pipeline: null }));
          dispatch(showMessage({ severity: 'success', message: 'success' }));
        }
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    await sendDeployRequest();
  };

  const StageRadioGroup = () => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log(event.target.value);

      setRadioChecked((event.target as HTMLInputElement).value);
    };
    return (
      <FormControl>
        <FormLabel>Select stage</FormLabel>
        <RadioGroup value={radioChecked} onChange={onChange}>
          <FormControlLabel value={radioOptions[0]} control={<Radio />} label={radioOptions[0]} />
          <FormControlLabel value={radioOptions[1]} control={<Radio />} label={radioOptions[1]} />
        </RadioGroup>
      </FormControl>
    );
  };

  const CheckedGroup = ({ platforms }: { platforms: IPlatform[] }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newState = [...checked];
      const flag = newState.find(c => c.id === event.target.name);
      if (flag) {
        flag.checked = !flag.checked;
        setChecked(newState);
      }
    };

    const list = platforms.map(p => (
      <FormControlLabel
        control={
          <Checkbox checked={checked.find(c => c.id === p.id)?.checked} onChange={handleChange} name={p.id} />
        }
        key={p.id}
        label={p.name}
      />
    ));

    return (
      <FormControl>
        <FormLabel>Select platforms</FormLabel>
        <FormGroup>{list}</FormGroup>
      </FormControl>
    );
  };

  return (
    <>
      <Box sx={{ border: 'solid' }}>
        <CheckedGroup platforms={project.platforms!} />
      </Box>
      <Box sx={{ border: 'solid' }}>
        <StageRadioGroup />
      </Box>
      <Button onClick={onDeployClick}>Deploy</Button>
    </>
  );
};

export default DeployProject;
