import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Menu, Tooltip, IconButton, MenuItem, Button } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { useDispatch, useSelector } from 'react-redux';
import { logOut, selectUser } from './authSlice';
import useApi from '../../hooks/useApi';

const AuthUserButton = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const serverApi = useApi();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onProfileClick = () => {
    handleClose();
    navigate('/profile');
  };
  const onLogoutClick = async () => {
    serverApi.patch('/auth/logout');
    handleClose();
    dispatch(logOut());
    navigate('/');
  };
  const onEditClick = () => {
    handleClose();
    navigate('/edit');
  };
  return (
    <div>
      <Button
        color="inherit"
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {user?.login}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={onProfileClick}>Profile</MenuItem>
        <MenuItem onClick={onEditClick}>Edit</MenuItem>
        <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default AuthUserButton;
