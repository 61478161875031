import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, TextField } from '@mui/material';

import { selectUser } from '../auth/authSlice';

import useApi from '../../hooks/useApi';
import { showMessage } from '../alert/alertSlice';
import { IProject, setPlatforms } from './projectSlice';

interface IAddPlatformsBody {
  projectId: string;
  platforms: string[];
}

const DeleteProjectPlatform = ({ project }: { project: IProject }): JSX.Element => {
  const serverApi = useApi();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const onDeletePlatformClick = async (platformId: string) => {
    const deletePlatform = async () => {
      try {
        const { data } = await serverApi.delete('/platforms', { params: { platformId } });

        dispatch(setPlatforms({ projectId: project.id, platforms: data }));
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    await deletePlatform();
  };
  console.log(project);

  let platformsList: JSX.Element[] = [];
  if (project.platforms)
    platformsList = project?.platforms.map(p => (
      <>
        <p key={p.id}>{p.name}</p>
        <button onClick={() => onDeletePlatformClick(p.id)}>-</button>
      </>
    ));

  return (
    <>
      <div>Project Platforms</div>
      <ul>{platformsList}</ul>
    </>
  );
};

export default DeleteProjectPlatform;
