import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface IPipeline {
  id: number;
  branch: string;
  status: string;
  url: string;
  updatedAt: string;
  platformVariable: string[];
  stageVariable: string[];
}

export interface ISetPipeline {
  pipeline: IPipeline | null;
  projectId: string;
}

export interface IPlatform {
  id: string;
  name: string;
}

export interface ISetPlatforms {
  platforms: IPlatform[];
  projectId: string;
}

export interface IProject {
  id: string;
  name: string;
  branch: string;
  triggerToken: 0 | 1;
  pipeline: IPipeline | null;
  platforms: IPlatform[] | null;
}

export interface IProjectsState {
  projects: IProject[];
  updated?: boolean;
}
const initialState: IProjectsState = {
  projects: [],
  updated: false,
};

interface IUpdateState {
  project: IProject;
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    getProjects: {
      reducer(state, action: PayloadAction<IProjectsState>) {
        state.projects = action.payload.projects;
        state.updated = true;
      },
      prepare(projects: IProject[]): { payload: IProjectsState } {
        return {
          payload: {
            projects: projects.map(p => ({
              id: p.id,
              name: p.name,
              branch: p.branch,
              triggerToken: p.triggerToken,
              platforms: null,
              pipeline: null,
            })),
          },
        };
      },
    },
    updateProject: (state, action: PayloadAction<IUpdateState>) => {
      const index = state.projects.findIndex(p => p.id === action.payload.project.id);
      state.projects[index] = action.payload.project;
    },
    updateBranch: (state, action: PayloadAction<{ branch: string; projectId: string }>) => {
      const index = state.projects.findIndex(p => p.id === action.payload.projectId);
      state.projects[index].branch = action.payload.branch;
    },
    setPipeline: (state, action: PayloadAction<ISetPipeline>) => {
      const index = state.projects.findIndex(p => p.id === action.payload.projectId);
      state.projects[index].pipeline = action.payload.pipeline;
    },
    setPlatforms: (state, action: PayloadAction<ISetPlatforms>) => {
      const index = state.projects.findIndex(p => p.id === action.payload.projectId);
      state.projects[index].platforms = action.payload.platforms;
    },
    deleteProject: (state, action: PayloadAction<string>) => {
      state.projects = state.projects.filter(p => p.id !== action.payload);
    },
    changeStatus: state => {
      state.updated = !state.updated;
    },
  },
});

export default projectSlice.reducer;
export const {
  updateProject,
  getProjects,
  setPipeline,
  setPlatforms,
  deleteProject,
  changeStatus,
  updateBranch,
} = projectSlice.actions;
export const selectProjects = (state: RootState) => state.project.projects;
export const selectUpdated = (state: RootState) => state.project.updated;
export const selectProjectById = createSelector(
  [selectProjects, (state, projectId) => projectId],
  (projects, projectId) => projects.filter(p => p.id === projectId)[0],
);
