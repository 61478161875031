import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { SyntheticEvent, useEffect, useState } from 'react';
import AddProjectPlatform from './AddProjectPlatform';
import GetProjectData from './GetProjectData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { IPipeline, IPlatform, IProject, selectProjectById, setPipeline, setPlatforms } from './projectSlice';
import { useNavigate, useParams } from 'react-router-dom';
import DeployProject from './DeployProject';
import useApi from '../../hooks/useApi';
import { showMessage } from '../alert/alertSlice';
import { LinearProgress } from '@mui/material';
import Loading from '../../components/Loading';
import DeleteProjectPlatform from './DeleteProject';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const ProjectTabs = (): JSX.Element => {
  const [value, setValue] = useState(0);
  const [isPlatformsLoading, setIsPlatformsLoading] = useState(true);
  const [isPipelineLoading, setIsPipelineLoading] = useState(true);
  const serverApi = useApi();
  const dispatch = useDispatch();

  const { projectId } = useParams();
  // alert(projectId);
  const project = useSelector<RootState, IProject | undefined>(state => selectProjectById(state, projectId));

  if (!project) return <h2>Project not found</h2>;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchPipeline = async () => {
      try {
        setIsPipelineLoading(true);
        const { data } = await serverApi.get<IPipeline>(`/pipelines/${projectId}`, {
          signal: controller.signal,
        });

        if (!project.pipeline) dispatch(setPipeline({ pipeline: data, projectId: projectId || project.id }));
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      } finally {
        setIsPipelineLoading(false);
      }
    };

    fetchPipeline();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [project, projectId]);

  // SET PLATFORMS

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchPlatforms = async () => {
      try {
        setIsPlatformsLoading(true);
        const { data } = await serverApi.get<IPlatform[]>(`/platforms/${project.id}`, {
          signal: controller.signal,
        });

        if (!project.platforms) dispatch(setPlatforms({ platforms: data, projectId: project.id }));
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      } finally {
        setIsPlatformsLoading(false);
      }
    };

    fetchPlatforms();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [project]);

  if (isPipelineLoading || isPlatformsLoading) return <Loading />;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Info" {...a11yProps(0)} />
          <Tab label="Deploy" {...a11yProps(1)} />
          <Tab label="Add platform" {...a11yProps(2)} />
          <Tab label="Delete platform" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <GetProjectData project={project} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DeployProject project={project} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AddProjectPlatform project={project} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DeleteProjectPlatform project={project} />
      </TabPanel>
    </Box>
  );
};

export default ProjectTabs;
