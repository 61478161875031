import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';

export interface IOption {
  title: string;
  id: number | string;
}

interface ISelectorProps {
  options: IOption[];
  label: string;
  setSelectedValue: Dispatch<SetStateAction<IOption | null>>;
  selectedValue: IOption | null;
}

export default function Selector({ options, label, setSelectedValue, selectedValue }: ISelectorProps) {
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Autocomplete
      value={selectedValue}
      onChange={(event: any, newValue: IOption | null) => {
        setSelectedValue(newValue);
      }}
      id="selector"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={option => option.title}
      options={options}
      loading={loading}
      renderInput={params => (
        <TextField
          required
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
