import { Box, TextField, Button } from '@mui/material';
import AppPage from '../../components/AppPage';
import { FormEvent, useState } from 'react';
import { showMessage } from '../alert/alertSlice';
import useApi from '../../hooks/useApi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeStatus } from './projectSlice';

interface IAddProjectRequest {
  repoId: number;
  branch: string;
}

const AddProject = () => {
  const [branch, setBranch] = useState('');
  const [repoId, setRepoId] = useState('');

  const dispatch = useDispatch();
  const serverApi = useApi();
  const navigate = useNavigate();

  const onAddProjectSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (!(repoId && branch)) {
        dispatch(showMessage({ severity: 'error', message: 'All required fields are not filled in' }));
        return;
      }

      const body = { repoId: +repoId, branch } satisfies IAddProjectRequest;

      await serverApi.post('/projects', body);
      setRepoId('');
      setBranch('');
      dispatch(showMessage({ severity: 'success', message: 'Project added' }));
      dispatch(changeStatus());
      navigate('/projects');
    } catch (e) {
      if (e instanceof Error && e.message !== 'canceled')
        dispatch(showMessage({ severity: 'error', message: e.message }));
    }
  };

  return (
    <AppPage title="Add Project">
      <Box component="form" onSubmit={onAddProjectSubmit} noValidate>
        <TextField
          required
          margin="normal"
          fullWidth
          id="project-id"
          label="Project ID"
          name="repo id"
          autoFocus
          value={repoId}
          onChange={event => setRepoId(event.target.value)}
        />
        <TextField
          required
          margin="normal"
          fullWidth
          name="branch"
          label="Branch"
          id="branch"
          value={branch}
          onChange={event => setBranch(event.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Add
        </Button>
      </Box>
    </AppPage>
  );
};

export default AddProject;
