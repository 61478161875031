import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, Container, Alert } from '@mui/material';
import ButtonAppBar from './components/AppBar';
import AppRouter from './routes/AppRouter';
import MessageAlert from './features/alert/MessageAlert';
import PersistLogin from './features/auth/PersistLogin';

function App() {
  return (
    <BrowserRouter>
      <div>
        <CssBaseline />
        <MessageAlert />
        <ButtonAppBar />
        <PersistLogin />
      </div>
    </BrowserRouter>
  );
}

export default App;
