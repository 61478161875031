import { Box, TextField, Button } from '@mui/material';
import AppPage from '../../components/AppPage';
import { useEffect, useState } from 'react';
import { showMessage } from '../alert/alertSlice';
import useApi from '../../hooks/useApi';
import Selector, { IOption } from '../../components/Autocomplete';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface IRole {
  id: number;
  name: string;
}

const AddUser = () => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<null | IOption>(null);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const serverApi = useApi();
  const navigate = useNavigate();

  const onAddUserSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (!(selectedRole && password && login)) {
        dispatch(showMessage({ severity: 'error', message: 'All required fields not filled in' }));
        return;
      }

      const body = { login, password, roleId: selectedRole?.id };

      await serverApi.post('/users', body);
      setLogin('');
      setPassword('');
      dispatch(showMessage({ severity: 'success', message: 'User added' }));
      navigate('/users');
    } catch (e) {
      if (e instanceof Error && e.message !== 'canceled')
        dispatch(showMessage({ severity: 'error', message: e.message }));
    }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getRoles = async () => {
      try {
        const { data } = await serverApi.get<IRole[]>('/roles', {
          signal: controller.signal,
        });

        isMounted && setRoles(data);
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };

    getRoles();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <AppPage title="Add User">
      <Box component="form" onSubmit={onAddUserSubmit} noValidate>
        <TextField
          required
          margin="normal"
          fullWidth
          id="login"
          label="Login"
          name="login"
          autoComplete="login"
          autoFocus
          value={login}
          onChange={event => setLogin(event.target.value)}
        />
        <TextField
          required
          margin="normal"
          fullWidth
          name="password"
          label="Password"
          id="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
        <Selector
          options={roles.map(r => ({ id: r.id, title: r.name }))}
          selectedValue={selectedRole}
          setSelectedValue={setSelectedRole}
          label="Roles"
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Add
        </Button>
      </Box>
    </AppPage>
  );
};

export default AddUser;

/* <>
        
      </> */
