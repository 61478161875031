import serverApi from '../app/serverAPI';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCredentials, selectToken } from '../features/auth/authSlice';
import { IAuthState } from '../features/auth/authSlice';

const useApi = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  if (!token) return serverApi;
  const refresh = async () => {
    const { data } = await serverApi.get<IAuthState>('/auth/refresh');

    return data.accessToken!;
  };

  useEffect(() => {
    const requestIntercept = serverApi.interceptors.request.use(
      config => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
      },
      error => {
        Promise.reject(error);
      },
    );

    const responseIntercept = serverApi.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error?.config;

        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

          dispatch(getCredentials({ accessToken: newAccessToken }));

          return serverApi(prevRequest);
        }

        return Promise.reject(error);
      },
    );

    return () => {
      serverApi.interceptors.response.eject(requestIntercept);
      serverApi.interceptors.response.eject(responseIntercept);
    };
  }, []);

  return serverApi;
};

export default useApi;
