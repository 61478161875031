import { BrowserRouter, Link as RouterLink, useNavigate } from 'react-router-dom';

import { Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

interface IRouterListProps {
  selectMenuItem: React.Dispatch<React.SetStateAction<string>>;
}

const RouterList = ({ selectMenuItem }: IRouterListProps): JSX.Element => {
  const navigate = useNavigate();
  const items = [
    {
      id: 1,
      label: 'Projects',
      path: '/projects',
    },
    {
      id: 2,
      label: 'Users',
      path: '/users',
    },
  ];

  const list = items.map(({ id, label, path }) => {
    return (
      <ListItem key={id} disablePadding>
        <ListItemButton
          onClick={() => {
            selectMenuItem(label);
            navigate(path);
          }}
        >
          <ListItemIcon>{<FormatListBulletedIcon />}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
    );
  });

  return <List>{list}</List>;
};

export default RouterList;
