import { IProject } from './projectSlice';
import { Link } from '@mui/material';

const GetProjectData = ({ project }: { project: IProject }): JSX.Element => {
  return (
    <>
      {`
          id: ${project.id}`}
      <br />
      {`name: ${project.name}`}
      <br />
      {`platforms: ${project?.pipeline?.platformVariable.join(', ') ?? ''}`}
      <br />
      {`stage: ${project?.pipeline?.stageVariable.join(', ') ?? ''}`}
      <br />
      {`branch: ${project?.pipeline?.branch ?? ''}`}
      <br />
      {`updated at: ${project?.pipeline?.updatedAt ?? ''}`}
      <br />
      {`status: ${project?.pipeline?.status}`}
      <br />
      url:{' '}
      <Link href={project?.pipeline?.url} target="_blank">
        {project?.pipeline?.url ?? ''}
      </Link>
      <br />
    </>
  );
};

export default GetProjectData;
