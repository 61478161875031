import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UserTable from './UserTable';
import useApi from '../../hooks/useApi';
import { showMessage } from '../alert/alertSlice';

import { TUserRow } from './UserTable';

import AppPage from '../../components/AppPage';
import { TAuthUser, selectToken } from '../auth/authSlice';
import { useNavigate } from 'react-router-dom';

const Users = (): JSX.Element | null => {
  const [users, setUsers] = useState<TUserRow[]>([]);
  const serverApi = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const { data } = await serverApi.get<TAuthUser[]>('/users', {
          signal: controller.signal,
        });

        isMounted && setUsers(data.map(d => ({ id: d.id, login: d.login, role: d.role.name })));
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <AppPage title="User table">
      <>
        <Button onClick={() => navigate('/users/add')}>Add user</Button>
        <br />
        <UserTable rows={users} />
      </>
    </AppPage>
  );
};

export default Users;
