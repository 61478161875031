import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { AppBar, Box, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import TemporaryDrawer from './Drawer';
import SignInButton from '../features/auth/SigninButton';

export default function ButtonAppBar(): JSX.Element {
  const [drawerState, setDrawerState] = useState(false);
  const [title, setTitle] = useState('');

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerState(!drawerState);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
            <TemporaryDrawer open={drawerState} toggleDrawer={toggleDrawer} setAppBarTitle={setTitle} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <SignInButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
