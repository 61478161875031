import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, TextField } from '@mui/material';

import { selectUser } from '../auth/authSlice';

import useApi from '../../hooks/useApi';
import { showMessage } from '../alert/alertSlice';
import { IProject, setPlatforms } from './projectSlice';

interface IAddPlatformsBody {
  projectId: string;
  platforms: string[];
}

const AddProjectPlatform = ({ project }: { project: IProject }): JSX.Element => {
  const [inputPlatform, setInputPlatform] = useState('');
  const serverApi = useApi();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  if (user?.role.name !== 'admin') return <div>Not permitted</div>;

  const onAddPlatformClick = async () => {
    const sendPlatform = async () => {
      try {
        if (inputPlatform) {
          const { data } = await serverApi.post('/platforms', {
            projectId: project.id,
            platform: inputPlatform,
          });

          dispatch(setPlatforms({ projectId: project.id, platforms: data }));
          setInputPlatform('');
        }
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    await sendPlatform();
  };

  const addPlatform = (
    <>
      <TextField
        id="platform"
        label="Platform"
        value={inputPlatform}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setInputPlatform(event.target.value);
        }}
      />
      <Button onClick={onAddPlatformClick}>Add platform</Button>
    </>
  );

  return (
    <>
      <Box>{user?.role.name === 'admin' ? addPlatform : null}</Box>
    </>
  );
};

export default AddProjectPlatform;
