import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import serverApi from '../../app/serverAPI';
import { selectToken, IAuthState, getCredentials } from './authSlice';
import { showMessage } from '../alert/alertSlice';
import { useNavigate } from 'react-router-dom';
import AppPage from '../../components/AppPage';

import { Button, TextField, Box, Typography, Paper, Container } from '@mui/material';

export default function SignIn(): JSX.Element | null {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSigninSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const sendSigninRequest = async () => {
      try {
        if (password && login) {
          const { data } = await serverApi.post<IAuthState>('/auth/signin', { login, password });
          dispatch(getCredentials(data));
          navigate('/projects');
        }
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    await sendSigninRequest();
  };

  return (
    <AppPage title="Sign in">
      <Box component="form" onSubmit={onSigninSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="login"
          label="Login"
          name="login"
          autoComplete="login"
          autoFocus
          value={login}
          onChange={event => setLogin(event.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
      </Box>
    </AppPage>
  );
}
