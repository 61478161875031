import { Button } from '@mui/material';

import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { selectToken } from './authSlice';
import AuthUserButton from './AuthUserButton';

const SignInButton = (): JSX.Element => {
  const token = useSelector(selectToken);

  if (!token)
    return (
      <Button color="inherit" component={RouterLink} to="/">
        Signin
      </Button>
    );

  return <AuthUserButton />;
};

export default SignInButton;
