import { Box, Container, Typography, Paper } from '@mui/material';
import { Children } from 'react';

interface IAppPageProps {
  title: string;
  children: JSX.Element;
}

const AppPage = ({ title, children }: IAppPageProps) => {
  return (
    <Container maxWidth="lg">
      <Paper sx={{ padding: '16px', mt: 5 }}>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Box sx={{ mt: '16px' }}>{children}</Box>
      </Paper>
    </Container>
  );
};

export default AppPage;
