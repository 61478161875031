import { ChangeEventHandler, Dispatch, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { changeStatus, updateBranch, updateProject } from './projectSlice';
import { showMessage } from '../alert/alertSlice';
import serverApi from '../../app/serverAPI';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../auth/authSlice';

interface ISubmitDialogProps {
  projectId: string;
}

interface IEditProject {
  token?: string;
  branch?: string;
  projectId: string;
}

export default function SubmitDialogButton({ projectId }: ISubmitDialogProps) {
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState('');
  const [branch, setBranch] = useState('');

  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onTriggerFormSubmit = async () => {
    try {
      const body = { projectId } as IEditProject;
      if (branch) body.branch = await serverApi.patch(`/projects/${projectId}`, { branch });

      let response;
      if (token) response = await serverApi.post('/users/project', { triggerToken: token, projectId });

      dispatch(changeStatus());
      if (branch) dispatch(updateBranch({ projectId, branch }));
      dispatch(showMessage({ severity: 'success', message: 'success' }));
    } catch (e) {
      if (e instanceof Error && e.message !== 'canceled')
        dispatch(showMessage({ severity: 'error', message: e.message }));
    }
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Edit project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="dialog-button"
            label="Trigger token"
            fullWidth
            variant="standard"
            value={token}
            onChange={e => setToken(e.target.value)}
          />
          <TextField
            margin="dense"
            id="dialog-button"
            label="Branch"
            fullWidth
            variant="standard"
            value={branch}
            disabled={user?.role.name === 'admin' ? false : true}
            onChange={e => setBranch(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              onTriggerFormSubmit();
              handleClose();
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
