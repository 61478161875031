import { ChangeEvent, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Link,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from '@mui/material';

import SubmitTriggerButton from './SubmitTriggerButton';
import AppPage from '../../components/AppPage';

import { showMessage } from '../alert/alertSlice';
import {
  IProject,
  deleteProject,
  getProjects,
  selectProjects,
  selectUpdated,
  updateProject,
} from './projectSlice';
import { selectUser } from '../auth/authSlice';
import serverApi from '../../app/serverAPI';

export type TProjectRow = { id: string; name: string; triggerToken: 0 | 1 };

interface IPostTriggerToken {
  projectId: string;
  triggerToken: string;
}

const ProjectTable = (): JSX.Element => {
  const projects = useSelector(selectProjects);
  const updated = useSelector(selectUpdated);
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchProjects = async () => {
      try {
        const { data } = await serverApi.get<IProject[]>('/projects', {
          signal: controller.signal,
        });
        if (isMounted) dispatch(getProjects(data));
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };

    if (!updated) fetchProjects();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [projects, dispatch]);

  const onDeleteClick = async (projectId: string) => {
    try {
      await serverApi.delete(`/projects/${projectId}`);
      dispatch(deleteProject(projectId));
      dispatch(showMessage({ severity: 'success', message: 'success' }));
    } catch (e) {
      if (e instanceof Error && e.message !== 'canceled')
        dispatch(showMessage({ severity: 'error', message: e.message }));
    }
  };

  return (
    <AppPage title="Project table">
      <div>
        {user && user.role.name === 'admin' ? (
          <Button onClick={() => navigate('/projects/add')}>Add project</Button>
        ) : null}

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Branch</TableCell>
                <TableCell align="right">Trigger token/branch</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map(row => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Link component={RouterLink} to={`/projects/${row.id}`}>
                      {row.id}
                    </Link>
                  </TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="right">{row.branch}</TableCell>
                  <TableCell align="right">
                    <SubmitTriggerButton projectId={row.id} />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      disabled={user?.role.name === 'admin' ? false : true}
                      onClick={() => onDeleteClick(row.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </AppPage>
  );
};

export default ProjectTable;
